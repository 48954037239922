import "jquery-address"

import { Bizq } from "./viz-base"

import * as Ui from "./util/ui"
import * as Strings from "./util/string"
import * as Form from "./util/form"
import { State } from "./util/state"
import { getLanguage } from "./util/language"
import { trilingual, getI18n } from "./viz/i18n"
import { Widget } from "./viz/widget"
import { PowerSearch } from "./powersearch"
import { registerSiteWidgets } from "./site/widgets"
import { initializeTooltips } from "./site/tooltip"
import { animateNumericLabel } from "./site/animation"
import {
  recreate as recreateSegmentCodeDropdown,
  clear as clearSegmentCodeDropdown,
} from "./site/search/segment-code-dropdown"
import {
  AddressSearch,
  initializeAddressSearch,
} from "./site/search/address-search"
import {
  drawSearchResultMap,
  SearchResultMap,
} from "./site/search/search-result-map"
import { importDataTable } from "./dynamic-modules"
import { addViewportHandler } from "./util/dom"
import { initializeDocumentShopTable } from "./site/data-table"
import { formatCurrency } from "./site/currency"
import { UpolloClient, EventType } from "@upollo/web"
import Rupt from "rupt"

if (top != self) {
  top!.location.replace(self.location.href)
}

const language = getLanguage()

registerSiteWidgets()

module Advertising {
  type ClickyFunc = (clicky: any) => void
  function exec(clickyFunc: ClickyFunc) {
    const clicky = (window as any)["clicky"]
    if (clicky) {
      clickyFunc(clicky)
    } else {
      window.setTimeout(function () {
        exec(clickyFunc)
      }, 500)
    }
  }
}

module Print {
  const printDialog = $(".modal.dossier")
  const printDialogOK = printDialog.find(".ok")
  function showPrintDialog() {
    printDialog["modal"]("show")
  }
  function submitPrintJob() {
    printDialogOK.addClass("loading")
    const params: Record<string, any> = {}
    $("form[name='dossier'] input").each(function () {
      const input = this as HTMLInputElement
      const key = input.getAttribute("name")
      if (key) {
        const checked = input.checked
        State.setValue(key, checked)
        if (checked) {
          params[key] = checked
        }
      }
    })
    const payload = {
      title: document.title,
      url: document.location.href,
      params,
    }
    $.ajax("/_render_pdf", {
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(payload),
      success: submitPrintJobOk,
    })
    return false
  }
  function submitPrintJobOk() {
    printDialogOK.removeClass("loading")
    printDialog["modal"]("hide")
  }
  printDialog["modal"]({ onApprove: submitPrintJob as () => void })
  const showDialogButton = $("#print")
  showDialogButton.click(showPrintDialog)
}

module Watch {
  const noteDialog = $(".modal.note")
  const noteDialogOK = noteDialog.find(".ok")
  const watchDialog = $(".modal.watch")
  const watchDialogOK = watchDialog.find(".ok")
  const watchDialogError = watchDialog.find(".ui.small.icon.message.error")
  function showWatchDialog() {
    watchDialog["modal"]("show")
  }
  function showNoteDialog(name: any, uri: any, note: any) {
    $("#note").val(note)
    noteDialog["modal"]("show")
    $(noteDialog).data("note-name", name)
    $(noteDialog).data("note-uri", uri)
  }
  function submitWatchJob() {
    watchDialogOK.addClass("loading")
    const params: Record<string, any> = {}
    $("form[name='dossier'] input").each(function () {
      const input = this as HTMLInputElement
      const key = input.getAttribute("name")
      if (key) {
        const checked = input.checked
        State.setValue(key, checked)
        if (checked) {
          params[key] = checked
        }
      }
    })
    const payload = {
      uri: watchDialog.data("watch-uri"),
      name: watchDialog.data("watch-name"),
      note: $("#note").val() as String,
    }
    $.ajax("/rpc.json/user/addWatch", {
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(payload),
      success: submitWatchJobOk,
    })
    return false
  }
  interface AddWatchResult {
    messageType: "error"
  }
  function submitWatchJobOk(data: AddWatchResult) {
    watchDialogOK.removeClass("loading")
    if (data.messageType === "error") {
      watchDialogError.removeClass("hidden")
    } else {
      watchDialog["modal"]("hide")
      document.location.assign("/_watches")
    }
  }
  function submitNoteJob() {
    noteDialogOK.addClass("loading")
    const payload = {
      uri: noteDialog.data("note-uri"),
      name: noteDialog.data("note-name"),
      note: $("#note").val() as String,
    }
    $.ajax("/rpc.json/user/updateWatch", {
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(payload),
      success: submitNoteJobOk,
    })
  }
  function submitNoteJobOk() {
    noteDialogOK.removeClass("loading")
    noteDialog["modal"]("hide")
    document.location.reload()
  }
  noteDialog["modal"]({ onApprove: submitNoteJob as () => void })
  watchDialog["modal"]({ onApprove: submitWatchJob as () => void })
  const showDialogButton = $("#watch")
  showDialogButton.click(showWatchDialog)
  const showNoteButton = $(".notebutton")
  showNoteButton.click(function () {
    const name = this.getAttribute("data-watch-name")
    const uri = this.getAttribute("data-watch-uri")
    const note = this.getAttribute("data-watch-note")
    showNoteDialog(name, uri, note)
  })
  const removeWatchButton = $(".remove-watch")
  removeWatchButton.click(function () {
    const payload = {
      name: this.getAttribute("data-watch-name"),
    }
    $.ajax("/rpc.json/user/removeWatch", {
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(payload),
      success: function () {
        document.location.reload()
      },
    })
  })
}

module OriginalDocumentShop {
  type ShopStatus =
    | "UNKNOWN"
    | "OK"
    | "NOT_AVAILABLE"
    | "OUT_OF_SERVICE"
    | "ONLY_PREMIUM"
    | "NO_CREDITS"
    | "NO_DOCUMENTS"

  interface DocumentData {
    title: string
    url: string
    date: string
    custom: {
      price: {
        amount: number
        unit: string
      }
      important: boolean
    }
  }

  interface ShopData {
    status: ShopStatus
    message?: string
    messageType: "info" | "error"
    documents: DocumentData[]
    forwardingFee: number
  }

  $(() => {
    const shopButton = $("#documentShop")
    if (!shopButton.length) {
      return
    }
    shopButton.on("click", showDocumentShopDialog)

    const shopBanner = document.querySelector(".shop-banner")
    if (shopBanner) {
      addViewportHandler(shopBanner, "shop-banner", () => {
        $(".shop-banner").addClass("animated")
      })
    }

    const shopDialog = $(".modal.document-shop")
    const shopDialogOk = shopDialog.find(".ok")
    const companyId = shopDialog.data("id")

    const pricingHint = shopDialog.find(".pricing-hint")
    const forwardingFeeLabel = shopDialog.find(".forwarding-fee")
    const shopInfoMessage = shopDialog.find(".ui.info.message")
    const shopErrorMessage = shopDialog.find(".ui.error.message")
    const shopPremiumMessage = shopDialog.find(".premium-message")
    const shopLoader = shopDialog.find(".loader")
    const shopProducts = shopDialog.find(".shop-products")
    const shopPriceLabel = shopDialog.find(".price-label")
    const documentTable = shopProducts.find("table")
    const documentList = documentTable.find("tbody")

    const currencyOptions =
      getLanguage() === "en" ? { prefix: true, plain: true } : {}

    const previewDialog = $(".modal.document-shop-preview")
    const previewDialogButtons = previewDialog.find(".actions button")
    const previewDialogOkButton = previewDialogButtons.filter(".ok")
    const previewTable = previewDialog.find("table")
    const previewList = previewTable.find("tbody")

    const shopConfirmationDialog = $(".modal.document-shop-confirmation")
    const successMessage = shopConfirmationDialog.find(".content.success")
    const errorMessage = shopConfirmationDialog.find(".content.error")

    const { dateFormatter } = getI18n()

    let isProcessingOrder = false
    function toggleProcessingOrder(state: boolean) {
      isProcessingOrder = state
      previewDialogButtons
        .toggleClass("disabled", state)
        .prop("disabled", state)
      previewDialogOkButton.toggleClass("loading", state)
      $(document.body).toggleClass("waiting", state)
    }

    function showConfirmationMessage(isSuccessfulOrder: boolean) {
      previewDialog.modal({
        duration: 0,
      })
      successMessage.toggle(isSuccessfulOrder)
      errorMessage.toggle(!isSuccessfulOrder)
      shopConfirmationDialog.modal("show")

      toggleProcessingOrder(false)
    }

    function getSelectedDocuments() {
      const documents: DocumentData[] = []
      if (!$.fn.dataTable.isDataTable(documentTable)) {
        return documents
      }

      documentTable
        .DataTable()
        .rows()
        .every(function () {
          const row = $(this.node())
          const checkbox = row.find("input:checkbox:checked")
          if (checkbox.length) {
            documents.push(row.data("document"))
          }
        })
      return documents
    }

    function getPriceInfo() {
      const shopData: ShopData = shopDialog.data("shopData")
      const forwardingFee = shopData.forwardingFee
      const selectedDocuments = getSelectedDocuments()

      let priceTotal = 0
      selectedDocuments.forEach(
        (doc) => (priceTotal += doc.custom.price.amount)
      )
      const forwardingFeeTotal = selectedDocuments.length * forwardingFee
      const grandTotal = priceTotal + forwardingFeeTotal
      return { priceTotal, forwardingFeeTotal, grandTotal }
    }

    function updateGrandTotalLabel() {
      const { forwardingFeeTotal, grandTotal } = getPriceInfo()
      shopPriceLabel
        .find(".fee-total")
        .text(formatCurrency(forwardingFeeTotal, "EUR", currencyOptions))
      shopPriceLabel
        .find(".grand-total")
        .text(formatCurrency(grandTotal, "EUR", currencyOptions))
      shopPriceLabel.show()
    }

    function onSelectDocument() {
      const selectedDocuments = getSelectedDocuments()
      const isDisabled = selectedDocuments.length === 0
      shopDialogOk
        .toggleClass("disabled", isDisabled)
        .prop("disabled", isDisabled)

      updateGrandTotalLabel()
    }

    function updateDocumentsDialog(data: ShopData) {
      shopDialog.data("shopData", data)

      const isOk = data.status === "OK"
      const documents = data?.documents ?? {}
      for (const doc of documents) {
        const checkbox = $("<div/>")
          .addClass("ui fitted checkbox")
          .append(
            $("<input/>")
              .attr("type", "checkbox")
              .prop("disabled", !isOk)
              .on("click", onSelectDocument)
          )
          .append("<label/>")

        const isImportant = doc.custom?.important === true
        const row = $("<tr/>")
          .append($("<td/>").addClass("center aligned").append(checkbox))
          .append($("<td/>").text(doc.title))
          .append($("<td/>").text(dateFormatter(doc.date)))
          .append(
            $("<td/>")
              .addClass("center aligned")
              .text(
                formatCurrency(
                  doc.custom.price.amount,
                  doc.custom.price.unit,
                  currencyOptions
                )
              )
          )
          .toggleClass("important", isImportant)
          .toggleClass("disabled", !isOk)

        row.data("document", doc)

        // Allow clicking the row to select the checkbox
        row.on("click", function (event: JQuery.TriggeredEvent) {
          if (event.target.type !== "checkbox") {
            $(this).find("input:checkbox").trigger("click").trigger("focus")
          }
        })

        documentList.append(row)
      }

      if (data.message) {
        const isError = data.messageType === "error"
        const messageLabel = isError ? shopErrorMessage : shopInfoMessage
        messageLabel.removeClass("hidden")
        messageLabel.find(".content").text(data.message)
      }

      const showPremiumMessage = data.status === "ONLY_PREMIUM"
      shopPremiumMessage.toggle(showPremiumMessage)

      forwardingFeeLabel.text(
        formatCurrency(data.forwardingFee, "EUR", currencyOptions)
      )

      initializeDocumentShopTable(documentTable)
      updateGrandTotalLabel()
      pricingHint.show()
      shopProducts.show()
      shopLoader.removeClass("active")
    }

    function orderDocuments(): false {
      if (isProcessingOrder) {
        return false
      }

      const productIds = getSelectedDocuments()
        .map((document) => document.url)
        .join("|")

      toggleProcessingOrder(true)
      $.ajax("/rpc.json/user/order", {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({ companyId, productIds }),
        success: (order: any) => {
          const isSuccessfulOrder = order?.status === "SUCCESSFUL"
          showConfirmationMessage(isSuccessfulOrder)
        },
        error: () => showConfirmationMessage(false),
      })
      return false
    }

    function showOrderPreview() {
      previewDialog
        .modal({
          autofocus: false,
          observeChanges: true,
          onHide: () => {
            if (isProcessingOrder) {
              return false
            }
          },
          onApprove: () => orderDocuments(),
          onDeny: (button: JQuery) => {
            if (isProcessingOrder) {
              return false
            }

            previewDialog.modal({ duration: 0 })
            if (button.data("backbutton")) {
              showDocumentShopDialog()
            }
          },
        })
        .modal("show")
    }

    function updateOrderPreview() {
      previewList.empty()

      const shopData: ShopData = shopDialog.data("shopData")
      if (shopData.status !== "OK") {
        return false
      }

      const selectedDocuments = getSelectedDocuments()
      if (!selectedDocuments.length) {
        return false
      }

      for (const doc of selectedDocuments) {
        const row = $("<tr/>")
          .append($("<td/>").text(doc.title))
          .append($("<td/>").text(dateFormatter(doc.date)))
          .append(
            $("<td/>")
              .addClass("right aligned")
              .text(
                formatCurrency(
                  doc.custom.price.amount,
                  doc.custom.price.unit,
                  currencyOptions
                )
              )
          )

        previewList.append(row)
      }

      const infoRow = (label: string, price: number) =>
        $("<tr/>")
          .append(
            $("<td/>").attr("colspan", 2).addClass("right aligned").text(label)
          )
          .append(
            $("<td/>")
              .addClass("right aligned")
              .text(formatCurrency(price, "EUR", currencyOptions))
          )

      const { priceTotal, forwardingFeeTotal, grandTotal } = getPriceInfo()
      const subtotalText = trilingual("Subtotal", "Zwischensumme", "Sous-total")
      const forwardingFeeText = trilingual(
        "Forwarding fee",
        "Weiterleitungsgebühr",
        "Frais de transmission"
      )
      previewList.append(infoRow(subtotalText, priceTotal))
      previewList.append(infoRow(forwardingFeeText, forwardingFeeTotal))
      previewTable
        .find(".grand-total")
        .text(formatCurrency(grandTotal, "EUR", currencyOptions))

      shopDialog.modal({ duration: 0 })
      showOrderPreview()
    }

    let documentsRequested = false
    function showDocumentShopDialog() {
      if (isProcessingOrder) {
        showOrderPreview()
        return
      }

      if (!documentsRequested) {
        documentsRequested = true

        $.ajax("/rpc.json/user/shop", {
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({ companyId }),
          success: updateDocumentsDialog,
        })

        shopLoader.addClass("active")
        shopInfoMessage.addClass("hidden")
        shopErrorMessage.addClass("hidden")
        shopPremiumMessage.hide()
        shopPriceLabel.hide()
        pricingHint.hide()
        shopProducts.hide()
      }

      shopDialog
        .modal({
          autofocus: false,
          observeChanges: true,
          onApprove: updateOrderPreview,
        })
        .modal("show")
    }
  })
}

module PremiumServices {
  $(() => {
    const cards = $(".premium-services__card")
    const buttons = $(".premium-services__control .button")

    buttons.on("click", (e) => {
      const button = $(e.currentTarget)
      if (button.hasClass("active")) {
        return
      }

      const planVariantId = button.data("id")
      if (!planVariantId) {
        return
      }

      const targetCard = cards.filter(
        (_index, element) => $(element).data("id") === planVariantId
      )
      if (targetCard.length) {
        buttons.removeClass("positive")
        cards.removeClass("active")
        button.addClass("positive")
        targetCard.addClass("active")
      }
    })
  })
}

module SearchExport {
  const exportDialog = $(".modal.export")
  const exportDialogOK = exportDialog.find(".ok")
  function showExportDialog() {
    exportDialog["modal"]("show")
  }
  function submitExportJob() {
    exportDialogOK.addClass("loading")
    const params = Strings.fromQueryString(document.location.search)
    const limit = $("form[name='export'] input[name='limit']").val()
    params["limit"] = limit
    $.ajax("/_sre", {
      type: "POST",
      contentType: "application/json",
      data: JSON.stringify(params),
      success: submitExportJobOk,
    })
    return false
  }
  function submitExportJobOk() {
    exportDialogOK.removeClass("loading")
    exportDialog["modal"]("hide")
  }
  exportDialog["modal"]({ onApprove: submitExportJob as () => void })
  const showDialogButton = $("#export,#export2")
  showDialogButton.click(showExportDialog)
}

module Geo {
  let addressSearch: AddressSearch
  let searchResultMap: SearchResultMap

  export function initSearchBox(searchElement: HTMLElement) {
    addressSearch = initializeAddressSearch(searchElement)
  }

  export function restrictAutocomplete(countries: string[]) {
    addressSearch.applyFilter(countries)
  }

  export function addNewSearchResultMarkers() {
    searchResultMap?.addNewMarkers()
  }

  $(async function () {
    const mapElement = document.getElementById("search-map")
    if (mapElement) {
      searchResultMap = await drawSearchResultMap(mapElement)
    }
  })
}

// NAG SUPPORT
$(".message .close-message").on("click", function (e) {
  e.preventDefault()

  const message = $(this).closest(".message")
  message["transition"]("fade")
  State.setValue(message.data("key"), "hide")
})

function dump(name: string, object: any): void {
  try {
    console.info(name, JSON.stringify(object, null, 2))
  } catch (error) {
    console.info(name, object)
  }
}

module Site {
  Form.manageAll()

  initializeTooltips()

  $(function () {
    const $closeButton = $(".search .label .link")
    const $searchInput = $(".search input")
    if ($closeButton.length > 0 && $searchInput.length > 0) {
      const closeButtonUpdate = function () {
        const searchInputText = $searchInput.val()
        const hasText =
          typeof searchInputText === "string" && searchInputText.length > 0
        $closeButton.toggleClass("visible", hasText)
      }
      $searchInput.on("input", closeButtonUpdate)
      $closeButton.on("click", function () {
        $closeButton.toggleClass("visible", false)
        $searchInput.val("").focus()
      })
      closeButtonUpdate()
    }

    // Semantic-UI based-search box
    type SearchEntry = {
      name: string
      title: string
      description: string
      url: string
      type: "company" | "person"
    }

    const COUNTRY_DELIMITER = "|"
    const universalCountrySelection = $(
      ".universal-search .ui.country.dropdown"
    )
    function getSelectedCountries() {
      const value: string = universalCountrySelection.dropdown("get value")
      return value.split(COUNTRY_DELIMITER).sort().join(COUNTRY_DELIMITER)
    }

    const simpleSearchInput = $(".ui.search.simple")
    simpleSearchInput.search({
      apiSettings: {
        url: "/suggest.json?query={query}&countries={countries}",
        beforeSend: function (settings) {
          settings.urlData.countries = getSelectedCountries()
          return settings
        },
        onResponse: function (response) {
          const categories = {
            company: {
              name: trilingual("Company", "Firma", "Entreprise"),
              results: [] as SearchEntry[],
            },
            person: {
              name: "Person",
              results: [] as SearchEntry[],
            },
          }
          if (response && response.results) {
            const counts: { [key: string]: number } = {}
            const entryKey = (entry: SearchEntry) =>
              entry.title + entry.description
            const getCount = (entry: SearchEntry): number =>
              counts[entryKey(entry)] || 0
            const setCount = (entry: SearchEntry, count: number): number =>
              (counts[entryKey(entry)] = count)
            const incCount = (entry: SearchEntry): number =>
              setCount(entry, getCount(entry) + 1)
            for (let i = 0; i < response.results.length; ++i) {
              const result = response.results[i] as SearchEntry
              const category = categories[result.type]
              if (category && category.results) {
                incCount(result)
                if (result.title.length > 1) {
                  category.results.push(result)
                }
              }
            }
            // deduplicate people
            const personResults = [] as SearchEntry[]
            const seen: { [key: string]: boolean } = {}
            for (const result of categories.person.results) {
              const count = getCount(result)
              if (count == 1) {
                personResults.push(result)
              } else {
                const key = entryKey(result)
                if (!seen[key]) {
                  seen[key] = true
                  const url = result.url
                  const index = url.lastIndexOf("/")
                  result.url = url.substring(0, index)
                  result.description =
                    result.description +
                    " (" +
                    count +
                    " " +
                    trilingual("results", "Resultate", "résultats") +
                    ")"
                  personResults.push(result)
                }
              }
            }
            categories.person.results = personResults
          }
          const content = { results: categories }
          return content
        },
      },
      onSelect: function (result, response) {
        if (result) {
          if (result.url) {
            simpleSearchInput.addClass("loading")
            // Fomantic will redirect to the selected result
            return
          }
          let name = Strings.unescape(result.name)
          if (name) {
            let query = name
            let address = Strings.unescape(result.description)
            if (address) {
              const indexOfPostalCode = address.indexOf("D-")
              if (indexOfPostalCode >= 0) {
                address = address.substring(indexOfPostalCode + 8)
              }
              query += ", " + address
            }
            search(query)
          }
        }
      },
      type: "category",
    })

    function search(query: string) {
      if (query) {
        simpleSearchInput.addClass("loading")
        // 1st try: encode dot:  does not work because Chrome immediately deencodes
        //const path = "/" + Strings.encode(query.trim()).replace(/\./g, "%2E")
        // 2nd try: replace dot by space - not nice, but works
        let path = "/" + Strings.encode(query.trim()).replace(/\./g, " ")
        if (query == "admin") {
          path += "?s=1" // hide the entry point of the admin UI
        }
        window.location.assign(path)
      }
    }

    $(document).on("submit", "form[role='search']", function (event) {
      event.preventDefault()
      const query = $(this).find("input[name='query']").val()
      if (typeof query === "string") {
        search(query)
      }
    })

    $(document).on(
      "click",
      "form[role='search'] .simple.search i.icon",
      function () {
        $(this).closest("form").submit()
      }
    )

    const params = Strings.fromQueryString(document.location.search)
    $(function () {
      type Runner = () => void
      const runners = (window as any)["runners"] as Runner[]
      if (runners) {
        for (const runner of runners) {
          runner()
        }
      }
    })

    $(".search-select .item")["tab"]()
    if (params["search"] == "power") {
      $("a[data-tab=power]").click()
      PowerSearch.writePowerSearchInput(params)
    }
    $(document).on("submit", "form.power-search", function (event) {
      event.preventDefault()
      $(this).find("button[type=submit]").addClass("loading")
      const params = PowerSearch.readPowerSearchInput()
      const path = "/?" + Strings.toQueryString(params)
      window.location.assign(path)
    })

    $(document.body).on("click", "a[data-load-href]", function (e) {
      if (e.isDefaultPrevented() || e.metaKey || e.ctrlKey) {
        return
      }
      const href: string = this.getAttribute("data-load-href") ?? ""
      const target: string = this.getAttribute("data-load-target") ?? ""
      const replace: boolean = "true" == this.getAttribute("data-load-replace")
      const $target = $(target) || $(this)
      Ui.setWaiting(true)
      $(target).load(href, (_responseText, _textStatus, jqXHR) => {
        jqXHR
          .done(() => {
            // on success
            if (replace) {
              $target.children().appendTo($target.parent())
              $target.remove()
            }
            Geo.addNewSearchResultMarkers()
          })
          .always(() => {
            Ui.setWaiting(false)
          })
      })
      e.preventDefault()
    })

    // Potential issue: this initialization is very general and will override the ones that happen before (e.g. in power search)
    $(".ui.dropdown:not(.indicator):not(.country)").dropdown({
      message: {
        noResults: trilingual(
          "No results",
          "Keine Resultate",
          "Aucun résultats"
        ),
      },
      fullTextSearch: true,
    })

    $(".ui.address.search").each((_, element) => Geo.initSearchBox(element))

    $(".ui.distance-unit.dropdown").dropdown({
      onChange(value: string) {
        State.setValue("distanceUnit", value)
      },
    })

    const segmentCodeStandardDropdown = $(".ui.segment-code-standard.dropdown")
    segmentCodeStandardDropdown.dropdown({
      onChange(value: string) {
        State.setValue("standard", value)
        clearSegmentCodeDropdown()
        recreateSegmentCodeDropdown(value)
      },
    })

    recreateSegmentCodeDropdown(
      segmentCodeStandardDropdown.dropdown("get value")
    )

    const powerCountrySelection = $(".power-search .ui.country.dropdown")
    const availableCountries = powerCountrySelection
      .find(".item")
      .map((_, menuItem) => menuItem.dataset.value!.toLowerCase())
      .get()
    const getCountryRestriction = (selectedCountries?: string) =>
      typeof selectedCountries === "string" && selectedCountries.length > 0
        ? selectedCountries.toLowerCase().split(",")
        : availableCountries

    powerCountrySelection.dropdown({
      action: "combo",
      onChange(value: string) {
        Geo.restrictAutocomplete(getCountryRestriction(value))
      },
    })
    if (powerCountrySelection.length) {
      Geo.restrictAutocomplete(
        getCountryRestriction(powerCountrySelection.dropdown("get value"))
      )
    }

    universalCountrySelection.dropdown({
      action: "combo",
      delimiter: COUNTRY_DELIMITER,
      label: {
        transition: "fade left",
      },
      onChange(value) {
        State.setValue("countries", value)

        // clear existing results and cache of autocomplete so we get results from selected countries
        simpleSearchInput.search("clear cache")

        // the results container is reinitialized, where refresh is used to invalidate the existing reference
        simpleSearchInput.children(".results").remove()
        simpleSearchInput.search("refresh results")
        simpleSearchInput.search("create results")
      },
      onLabelSelect(selectedLabel) {
        if (selectedLabel) {
          universalCountrySelection.dropdown("toggle")
        }
      },
    } as SemanticUI.DropdownSettings.Param)
    // Do not open popup menu on icon click as there is a tooltip attached
    universalCountrySelection
      .find(".info.icon")
      .on("click touchstart", (event) => event.stopPropagation())

    // Lightbulp animation
    $(".outline.lightbulb.icon")
      .closest(".ui.primary.segment")
      .on("mouseover touch", function () {
        if (!sessionStorage.getItem("lightbulbAnimation")) {
          $(this)
            .find(".outline.lightbulb.icon")
            .css("animation-play-state", "running")
          sessionStorage.setItem("lightbulbAnimation", "started")
        }
      })

    const productStatistics = document.querySelector(".product-statistics")
    if (productStatistics != null) {
      addViewportHandler(productStatistics, "statistics", () => {
        $(".product-statistics .ui.label").addClass("animate-badge")
        $(".product-statistics .ui.label .value").each((_, element) => {
          animateNumericLabel(element, 100, 1500, 0.3)
        })
      })
    }

    const journalismCounter = document.querySelector(".journalism-counter")
    if (journalismCounter != null) {
      addViewportHandler(journalismCounter, "journalism", () => {
        $(journalismCounter)
          .addClass("animate-badge")
          .each((_, element) => animateNumericLabel(element, 100, 1500, 0.3))
      })
    }

    $(".download-link").each(function () {
      const link = $(this)
      if (!link.attr("download")) {
        link.attr("download", "")
      }
    })

    $(".ui.accordion").accordion()
    $(".admin-bar.ui.accordion").accordion("open", 0)
  })
}

module WidgetInit {
  let barChartTabIndex = 0
  const print = $("html").hasClass("print")

  function handleBarChartItem(
    title: string,
    element: HTMLElement
  ): HTMLElement {
    const firstTab = barChartTabIndex == 0
    const tabClass = print ? "" : "ui bottom top attached tab "
    const activeClass = firstTab ? " active" : ""
    const tabName = "tab-bc-" + barChartTabIndex
    const headerItem = $("<a>")
      .addClass("item" + activeClass)
      .attr("data-tab", tabName)
      .text(title)
    const censoredHeaderItems = $(".bar-charts .menu [data-censored]")
    if (censoredHeaderItems.length > 0) {
      headerItem.insertBefore(censoredHeaderItems.first())
    } else {
      headerItem.appendTo(".bar-charts .menu")
    }
    const $tabContent = $("<div>")
      .addClass("chart " + tabClass + activeClass)
      .attr("data-tab", tabName)
      .append($("<h4>").addClass("print-only").text(title))
      .append(element)
    barChartTabIndex++
    return $tabContent[0]
  }

  let drillDownTabIndex = 0
  function handleDrillDownItem(
    title: string,
    element: HTMLElement
  ): HTMLElement {
    const firstTab = drillDownTabIndex == 0
    const classesSuffix = firstTab ? " active" : ""
    const tabName = "tab-dd-" + drillDownTabIndex
    $("<a>")
      .addClass("item" + classesSuffix)
      .attr("data-tab", tabName)
      .text(title)
      .appendTo(".drill-downs .menu .right.menu")

    const $tabContent = $("<div>")
      .addClass("chart ui tab" + classesSuffix)
      .attr("data-tab", tabName)
      .append($("<h4>").addClass("print-only").text(title))
      .append(element)
    drillDownTabIndex++
    return $tabContent[0]
  }

  function handleSheetItem(title: string, element: HTMLElement): HTMLElement {
    const $container = $("<div>")
      /*.append(
          $("<h4>")
            .addClass("print-only")
            .text(title)
          )*/

      .append(element)
    return $container[0]
  }

  function handleItem(this: Widget, title: string, element: HTMLElement) {
    const widgetType = this.getType()
    const item = element.getAttribute("data-item")
    if (item) {
      $("[data-show-for='" + item + "']").attr("data-show", "true")
    }
    switch (widgetType) {
      case "barChart":
        return handleBarChartItem(title, element)
      case "drillDown":
        return handleDrillDownItem(title, element)
      case "sheet":
        return handleSheetItem(title, element)
    }
  }

  $(function () {
    const popup = function (
      title: string,
      html: string,
      options: { publicationId: number }
    ) {
      $(".modal.report .header").html(title)
      $(".modal.report .content").html(html)
      $(".modal.report .content").attr("width", "") // sanitize external content!
      $(".modal.report")["modal"]()
      $(".modal.report")["modal"]("show")
      //console.info("options", options)
      if (options) {
        if (options["publicationId"]) {
          const url = "/?id=" + options["publicationId"]
          $(".modal.report .showpub").show()
          $(".modal.report .showpub").attr("href", url)
        }
      }
      $(".modal.report .content").prop("scrollTop", 0)
    }
    let widgetsLoading = 0
    let widgetsLoaded = 0
    const loadingEnded = function () {
      --widgetsLoading
      if (widgetsLoading == 0) {
        // disable all print links
        $(".print a").each(function () {
          const node = this
          const href = node.getAttribute("href")
          if (href && href[0] != "#") {
            node.removeAttribute("href")
          }
        })
        ;(document as any)["bizqPageComplete"] = true
        const div = document.createElement("div")
        div.setAttribute("id", "bizqPageComplete")
        document.body.appendChild(div)
      }
    }
    $(".tabular.menu .item, .pointing.menu .item").tab()
    $("[data-layout]").each(function () {
      const $container = $(this)
      let width = Number($container.innerWidth())
      const tabName = $(this).parent(".tab").attr("data-tab")
      const $network = $container.closest(".network")
      const $charts = $container.closest(".financial.charts")
      const $treemaps = $container.closest(".financial.charts")
      const layout = $container.attr("data-layout")
      let maxItems = 12
      if (width < 900) {
        maxItems = 10
        if (width < 600) {
          maxItems = 8
          if (width < 400) {
            maxItems = 6
          }
        }
      }
      if (layout == "history") {
        maxItems = 20
        if (width < 900) {
          maxItems = 17
          if (width < 600) {
            maxItems = 12
            if (width < 400) {
              maxItems = 8
            }
          }
        }
      }
      let height
      let minHeight
      let data
      if ($charts.length > 0) {
        const $chart = $charts.children(".chart")
        height = $charts.children(".chart").height() ?? height
        width = $charts.children(".chart").width() ?? width
        minHeight = 270
      }
      ++widgetsLoading
      const companyClick = function (data: { url: string }) {
        const url = data["url"]
        if (url) {
          window.location.assign(url)
        }
      }
      new Bizq.Widget(this, {
        rootColor: "#66afac",
        highlightBgColor: "#f3f4f5",
        language,
        minHeight,
        width,
        height,
        data,
        popup,
        handleItem,
        linkify: (element: Element) => element.getAttribute("data-url"),
        companyClick: layout == "barChart" ? companyClick : null,
        load: function () {},
        success: function (this: Widget) {
          const drawMe = this.draw.bind(this)
          if (tabName) {
            $("a[data-tab='" + tabName + "']").click(drawMe)
          }
          if (layout == "barChart") {
            $(".bar-charts .menu .item")["tab"]()
          }
          if (layout == "drillDown") {
            $(".drill-downs .menu .item")["tab"]()
          }
          if (layout == "financials" || layout == "sheet") {
            $container
              .find("table")
              .addClass("ui very compact celled small styled table")
          }
          loadingEnded()
          $(window).resize(drawMe)
          $("[data-show-for='" + layout + "']").attr("data-show", "true")
          if (layout == "graph" || layout == "dendrogram") {
            $network.find(".dimmer").hide()
            $network.css("height", ($container.height() ?? 0) + 14)
            $network.find("figure").css("opacity", 1)
            // debug distances
            $("body").keyup(function (event) {
              let keyCode = event.keyCode
              if (event.altKey && event.ctrlKey && keyCode == 68 /* d */) {
                event.preventDefault()
                $(".link[data-length]", $container).each(function () {
                  const length = this.getAttribute("data-length")
                  const text = $(this).find("text")
                  text.text(text.text() + " " + length)
                })
                $(".node[data-d]", $container).each(function () {
                  const rootDistance = this.getAttribute("data-d")
                  const text = $(this).find(".text")
                  //console.info(text.text() + ": " + rootDistance)
                })
              }
            })
          }
        },
        error: function (error: unknown) {
          loadingEnded()
          console.error("failed to create " + layout, error)
          $("[data-show-for='" + layout + "']").attr("data-show", "false")
          if (layout == "graph" || layout == "dendrogram") {
            $network.find(".dimmer").hide()
            $network.css("height", "55px")
          }
        },
        maxItems,
      })
    })
  })
}

module Publication {
  $(() => {
    $(".image-panel").each(function () {
      const imagePanel = $(this)
      const imageWrapper = $(`<div class="image-wrapper"><img></div>`)
      imageWrapper.insertBefore(imagePanel)

      imagePanel.find(".image-wrapper").on("click", function (event) {
        event.preventDefault()
        $(this).siblings().removeClass("active")
        $(this).addClass("active")
        imageWrapper
          .find("img")
          .attr("src", $(this).find("img").attr("src") as string)
      })

      imagePanel.find(".image-wrapper").first().trigger("click")
    })
  })
}

module InnovationRanking {
  $(() => {
    if ($("table.dataTable").length) {
      importDataTable().then(({ initializeDataTables }) =>
        initializeDataTables()
      )
    }
  })

  const countrySelection = $(".ui.country-select .ui.dropdown.country")
  countrySelection.dropdown({
    onChange: (value) => {
      countrySelection.addClass("loading")
      document.location.search = `innoCountry=${value}`
    },
  } as SemanticUI.DropdownSettings.Param)
}

module Coverage {
  $(() => {
    const coverageMenu = $("#coverage-menu .item")
    if (coverageMenu.length) {
      coverageMenu.tab({ history: true, historyType: "hash" })
    }
  })
}

module AccountSharing {
  async function installRupt(userId: string) {
    const url = window.location.protocol + "//" + window.location.host + "/"
    try {
      await Rupt.attach({
        client_id: "51c4ed55-8c1c-4e05-97f5-f430d40e4a1f",
        account: userId,
        email: userId,
        redirect_urls: {
          logout_url: url + "_logout",
          new_account_url: url + "_premium",
        },
      })
    } catch (error) {
      console.error("failed to load rupt", error)
    }
  }

  function installUpollo(userEmail: string) {
    console.info("loading Upollo")
    const upollo = new UpolloClient(
      "p6KbvVw8s73796bTr8gTLcozW4jgQFMoSxBetMg44nQgba0096993823a443c84f3b399fac90b118ce0bfb8a0c660cd731b394184f966f"
    )
    // Replace 12345 and person@example.com with the real ID and email of your user.

    upollo
      .track({ userEmail }, EventType.EVENT_TYPE_PAGE_VISIT)
      .then(() => console.info("Upollo loaded"))
      .catch((error) => console.error("Upollo fails with", error))
  }

  const accountEmail = document.body.getAttribute("data-account-email")
  if (accountEmail) {
    const ruptIsActive = document.body.getAttribute("data-rupt") === "true"
    const isDynamicPage = document.body.getAttribute("data-dynamic") === "true"
    if (ruptIsActive && isDynamicPage) {
      installRupt(accountEmail)
        .then(() => console.info("Rupt loaded"))
        .catch((error) => console.error("failed to load Rupt", error))
    }
    const upollo = document.body.getAttribute("data-upollo") === "true"
    if (upollo) {
      installUpollo(accountEmail)
    }
  }
}

module Carousel {
  type CarouselRotation = "left" | "right"

  function moveToCarouselPage(
    carousel: JQuery<HTMLElement>,
    page: JQuery<HTMLElement>,
    rotation: CarouselRotation
  ) {
    const carouselContent = carousel.find(".carousel-content")
    const activePage = carouselContent.find(".carousel-page.active")

    // Prevent actions while page is still animating
    if (activePage.queue().length) {
      return
    }

    const pageIndex = page.index() as number
    const carouselNavItems = carousel.find(".carousel-nav").children()
    carouselNavItems.filter(".active").removeClass("active")
    carouselNavItems.eq(pageIndex).addClass("active")

    // Hide all inactive pages to avoid issues when resizing the window
    const isRightRotation = rotation === "right"
    const width = carouselContent.outerWidth() as number
    carouselContent
      .find(".carousel-page:not(.active)")
      .animate({ left: isRightRotation ? width : -width }, 1)

    activePage
      .removeClass("active")
      .animate({ left: isRightRotation ? -width : width })
    page.addClass("active").animate({ left: 0 })
  }

  function getCarouselPage(
    carousel: JQuery<HTMLElement>,
    rotation: CarouselRotation
  ) {
    const isRightRotation = rotation === "right"
    const currentPage = carousel.find(".carousel-page.active")
    let page = isRightRotation
      ? currentPage.next(".carousel-page:not('.active')")
      : currentPage.prev(".carousel-page:not('.active')")
    if (page.length === 0) {
      const tabs = currentPage.siblings(".carousel-page:not('.active')")
      page = tabs.eq(isRightRotation ? 0 : tabs.length - 1)
    }
    return page
  }

  function onNavigationClick(navItem: JQuery<HTMLElement>) {
    const carousel = navItem.closest(".carousel-container")
    const pages = carousel.find(".carousel-page")
    const activePageIndex = pages.filter(".active").index() as number
    const index = navItem.index() as number
    if (index === activePageIndex || index >= pages.length) {
      return
    }

    const rotation: CarouselRotation =
      index < activePageIndex ? "left" : "right"
    const nextActivePage = pages.eq(index)
    moveToCarouselPage(carousel, nextActivePage, rotation)
  }

  function onCarouselButtonClick(
    button: JQuery<HTMLElement>,
    rotation: CarouselRotation
  ) {
    const carousel = button.parent()
    const nextActivePage = getCarouselPage(carousel, rotation)
    moveToCarouselPage(carousel, nextActivePage, rotation)
  }

  $(() => {
    $(".carousel-container").each(function () {
      const carousel = $(this)
      const carouselContent = carousel.find(".carousel-content")
      const carouselNav = carousel.find(".carousel-nav")
      const pages = carousel.find(".carousel-page")

      if (pages.length <= 1) {
        carousel.find(".carousel-button").css("display", "none")
        carouselNav.css("display", "none")
      }

      const offset = carouselContent.outerWidth() as number
      pages.each(function () {
        const page = $(this)
        const isActive = page.hasClass("active")
        if (!isActive) {
          page.css("left", offset)
        }

        $("<button/>")
          .addClass("carousel-nav-button")
          .toggleClass("active", isActive)
          .on("click", () => onNavigationClick($(this)))
          .appendTo(carouselNav)
      })
    })

    $(".carousel-button-left").on("click", function () {
      onCarouselButtonClick($(this), "left")
    })
    $(".carousel-button-right").on("click", function () {
      onCarouselButtonClick($(this), "right")
    })
  })
}
